import React, { useState, useEffect } from 'react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './Test.css';
import { TestData } from './../../data/TestData';
import { motion } from 'framer-motion';

function Test() {
    const animation = { type: 'spring', duration: 3 };
    const [current, setCurrent] = useState(0);
    
    const size = TestData.length;

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrent(current === size - 1 ? 0 : current + 1);
        }, 4000);

        return () => clearInterval(intervalId);
    }, [current, size]);

    return (
        <div className='Test' id="testimonials">
            <div className="blur test-blur"></div>
            <div className="left-t">
                <span>TESTIMONIALS</span>
                <span>HEAR FROM OUR PATIENTS</span>
                <motion.span key={current} initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }} transition={animation} className='review'>
                    {TestData[current].review}
                </motion.span>
                <span className='name'>
                    {TestData[current].name}
                </span>
                <span className='status'>
                    - {TestData[current].status}
                </span>
            </div>
            <div className="right-t">
                <motion.div initial={{ opacity: 0, x: -100 }} whileInView={{ opacity: 1, x: 0 }} transition={{ ...animation, duration: 2, type: 'tween' }} ></motion.div>
                <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{ ...animation, duration: 2, type: 'tween' }}></motion.div>
                <motion.img key={current} initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }} transition={animation} src={TestData[current].image} alt="" className='mainimg' />
                <div className="arrow">
                    <span onClick={() => setCurrent(current === 0 ? size - 1 : current - 1)}>
                        <FontAwesomeIcon icon={faArrowRight} rotation={180} style={{ color: "#199887" }} fontSize={50} />
                    </span>
                    <span onClick={() => setCurrent(current === size - 1 ? 0 : current + 1)}>
                        <FontAwesomeIcon icon={faArrowRight} style={{ color: "#199887" }} fontSize={50} />
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Test;
