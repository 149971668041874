import React, { useState, useEffect } from 'react';
import './services.css';
import de1 from './../../assets/1.png';
import de2 from './../../assets/2.png';
import de3 from './../../assets/3.png';
import de4 from './../../assets/4.png';
import { motion } from "framer-motion";

function Services() {
    const [isVisible, setIsVisible] = useState({
        mainText: false,
        subText: false,
        cards: [false, false, false, false],
    });

    useEffect(() => {
        const handleScroll = () => {
            const mainTextElement = document.querySelector('.main-text');
            const subTextElement = document.querySelector('.subtext');
            const cardElements = document.querySelectorAll('.card');

            const mainTextTop = mainTextElement.getBoundingClientRect().top;
            const subTextTop = subTextElement.getBoundingClientRect().top;

            const newIsVisible = {
                mainText: mainTextTop < window.innerHeight - 100,
                subText: subTextTop < window.innerHeight - 100,
                cards: Array.from(cardElements).map((element) => {
                    const elementTop = element.getBoundingClientRect().top;
                    return elementTop < window.innerHeight - 100;
                }),
            };

            setIsVisible((prev) => {
                return JSON.stringify(prev) === JSON.stringify(newIsVisible) ? prev : newIsVisible;
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isVisible]);

    return (
        <div className='services' id="services">
            <div className="blur services-blur"></div>
            <div className="blur services-blur-l"></div>
            <motion.div
                className="main-text"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: isVisible.mainText ? 1 : 0, y: isVisible.mainText ? 0 : 50 }}
                transition={{ duration: 2 }}
            >
                <span>"Top-Quality Dental Services for Every Smile"</span>
            </motion.div>
            <motion.div
                className='subtext'
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: isVisible.subText ? 1 : 0, y: isVisible.subText ? 0 : 50 }}
                transition={{ duration: 2 }}
            >
                <span>Discover our wide range of dental services designed to keep your smile healthy and radiant. From routine cleanings to advanced cosmetic procedures, our expert team is dedicated to providing top-notch care tailored to your unique needs. Explore our services below and find the perfect solution for your dental health.</span>
            </motion.div>
            <div className="cards">
                {['MEDICAL CHECK UP', 'EMERGENCY DENTAL CARE', 'SPECIALIZED TREATMENTS', 'GENERAL DENTISTRY'].map((title, index) => (
                    <motion.div
                        key={index}
                        className="card"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: isVisible.cards[index] ? 1 : 0, y: isVisible.cards[index] ? 0 : 50 }}
                        transition={{ duration: 2 }}
                    >
                        <motion.img
                            src={index === 0 ? de1 : index === 1 ? de2 : index === 2 ? de3 : de4}
                            alt=""
                            className='anim2'
                            initial={{ scale: 0 }}
                            whileInView={{ rotate: 360, scale: 1 }}
                            transition={{ type: "spring", stiffness: 600, damping: 200 }}
                        />
                        <div>
                            <span>{title}</span>
                        </div>
                        <ul>
                            {index === 0 && <>
                                <li>THOROUGH ASSESSMENTS BY OUR DENTAL PROFESSIONALS</li>
                                <li>ROUTINE EXAMINATIONS</li>
                                <li>ENSURING HEALTHY TEETH AND GUMS</li>
                                <li>EVALUATION AND MAINTENANCE OF ORAL HEALTH</li>
                            </>}
                            {index === 1 && <>
                                <li>IMMEDIATE ATTENTION FOR SUDDEN, CRITICAL DENTAL ISSUES</li>
                                <li>PAIN RELIEF AND URGENT TREATMENTS</li>
                                <li>ROOT CANAL TREATMENT</li>
                                <li>TOOTH EXTRACTION</li>
                            </>}
                            {index === 2 && <>
                                <li>ADVANCED PROCEDURES FOR SPECIFIC DENTAL CONDITIONS</li>
                                <li>ORTHODONTICS</li>
                                <li>DENTAL IMPLANTS</li>
                                <li>COSMETIC DENTISTRY</li>
                            </>}
                            {index === 3 && <>
                                <li>TEETH CLEANING</li>
                                <li>TEETH POLISHING</li>
                                <li>ENSURING HEALTHY TEETH AND GUMS</li>
                                <li>LASER FILLING</li>
                            </>}
                        </ul>
                    </motion.div>
                ))}
            </div>
        </div>
    );
}

export default Services;
