import React, { useState } from 'react';
import './Header.css';
import Logo from './../../assets/logo.png';

function Header() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="header">
            <div className="lo">
                <img src={Logo} alt="Logo" className='logo' />
            </div>
            <button className={`navTrigger ${isMobileMenuOpen ? 'active' : ''}`} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                <svg viewBox="0 0 64 48">
                    <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
                    <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
                    <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
                </svg>
            </button>
            <ul className={`header-menu ${isMobileMenuOpen ? 'active' : ''}`}>
                <li onClick={() => { scrollToSection('home'); setIsMobileMenuOpen(false); }}>Home</li>
                <li onClick={() => { scrollToSection('about-us'); setIsMobileMenuOpen(false); }}>About us</li>
                <li onClick={() => { scrollToSection('services'); setIsMobileMenuOpen(false); }}>Services</li>
                <li onClick={() => { scrollToSection('testimonials'); setIsMobileMenuOpen(false); }}>Testimonials</li>
            </ul>
        </div>
    );
}

export default Header;
