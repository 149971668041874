import React, { useState } from 'react';
import './App.css';
import Hero from './componenets/Hero/Hero';
import Services from './componenets/services/services'
import About from './componenets/About/About'
import Footer from './componenets/Footer/Footer'
import Test from './componenets/Testimonials/Test';
import Banner from './componenets/banner/Banner';
import Contact from './componenets/Contact/Contact'



function App() {
  const [showMainContent, setShowMainContent] = useState(true);

  const handleButtonClick = () => {
    setShowMainContent(false);
  };

  const backButtonClick = () => {
    setShowMainContent(true);
  };

  return (
    <div className="main">
      {showMainContent ? (
        <>
          <Hero onButtonClick={handleButtonClick} />
          <About />
          <Services />
          <Test />
          <Banner onButtonClick={handleButtonClick} />
          <Footer onButtonClick={handleButtonClick} />
         
        </>
      ) : (
        <>
          <Contact onButtonClick={backButtonClick} />
        </>
      )}
    </div>
  );
}

export default App;
