import React from 'react';
import './Banner.css';
import tooth22 from './../../assets/tooth22.png';
import dent from './../../assets/DENT.png';
import {motion} from 'framer-motion'


function Banner({ onButtonClick }) {
    
    const handleButtonClick = () => {
        onButtonClick();
      };
    const animation = {type:'spring', duration:3}
    return (
        <div className="banner">
            <div className="card-b">
                <span>We Are Ready To Serve Your Various Dental Problems!</span>
                <div className="buttons-container">
                <button className='button-left' onClick={() => window.location.href = 'tel:+923324403033'}>Call - +92 332 4403033</button>
                <div class="or-circle">OR</div>
                    <button className='button-right' onClick={handleButtonClick}>BOOK APPOINTMENT</button>
                </div>
                
               
                
            </div>
           
                  <motion.img
                        src={tooth22}
                        alt=""
                        className="pic1"
                        initial={{ opacity: 0, x: 200 }} // Initial position off-screen to the left
                        whileInView={{ opacity: 1, x: 0 }}   transition={animation}/>

                    <motion.img src={dent} alt="" className="pic2"initial={{ opacity: 0, x: -200 }} // Initial position off-screen to the left
                        whileInView={{ opacity: 1, x: 0 }}   transition={animation} />
            
        </div>
    );
} export default Banner;


