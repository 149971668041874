import React from 'react';
import './Contact.css';
import tooth from './../../assets/tooth22.png';
import { motion } from 'framer-motion';

function Contact({ onButtonClick }) {
    const handleButtonClick = () => {
        onButtonClick();
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        formData.append('access_key', '0b16e76c-effd-4947-a4f8-0c64598fe6ef'); 
        const response = await fetch('https://api.web3forms.com/submit', {
            method: 'POST',
            body: formData
        });

        if (response.ok) {
            alert('Email sent successfully!');
        } else {
            alert('Failed to send email.');
        }
    };

    const animation = { type: 'spring', duration: 3 };

    return (
        <div className="contact">
            <div className="container">
                <span className="big-circle" />
                <motion.img src={tooth} initial={{ opacity: 0, x: 200 }} whileInView={{ opacity: 1, x: 0 }} transition={animation} alt="" className="tooth" />
                <img alt="" className="square" src="img/shape.png" />
                <div className="form">
                    <div className="contact-info">
                        <button className="buttn" onClick={handleButtonClick}>Back to Home</button>
                        <h3 className="title">Let's get in touch</h3>
                        <span className="subtext">
                            "Ready for a brighter smile? We're here to help you achieve your dental goals."
                        </span>
                        <div className="info">
                            <div className="contact-left">
                                <i className="fas fa-envelope" />
                                <p>
                                    <a href="mailto:Mahibilal2018@gmail.com" title="Send email">Mahibilal2018@gmail.com</a>
                                </p>
                            </div>
                            <div className="contact-left">
                                <i className="fas fa-phone" />
                                <p>
                                    <a href="tel:+92 332 4403033" title="Call us">+92 332 4403033</a>
                                </p>
                            </div>
                        </div>
                        <div className="insta">
                            <p>Connect with us :</p>
                            <div className="insta-icon">
                                <a href="https://www.instagram.com/yourtoothduchess/" title="Instagram">
                                    <i className="fab fa-instagram" />
                                    <span>@yourtoothduchess</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="contact-form">
                        <span className="circle one" />
                        <span className="circle two" />
                        <form action="#" autoComplete="off" onSubmit={handleFormSubmit}>
                            <h3 className="title">Contact us</h3>
                            <div className="input-container">
                                <input className="input" id="name" name="name" type="text" required   placeholder="Name"/>
                                
                                
                            </div>
                            <div className="input-container">
                                <input className="input" id="email" name="email" type="email" required  placeholder="Email"/>
                               
                            </div>
                            <div className="input-container">
                                <input className="input" id="phone" name="phone" type="tel" required  placeholder='Phone'/>
                              
                            </div>
                            <div className="input-container textarea">
                                <textarea className="input" id="message" name="message" required placeholder='Message'  />
                               
                            </div>
                            <input className="btn" type="submit" value="Send" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
