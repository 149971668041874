import React from 'react';
import './Hero.css';
import Header from '../Header/Header';
import { motion } from 'framer-motion';
import NumberCounter from 'number-counter';
import anim1 from './../../assets/Blue And White Simple Modern Medical Service Flyer A4 (5)_page-0002.jpg'; // Assuming anim1 is an image
import heroimg from './../../assets/hero.png';
import back from './../../assets/back.png';


function Hero({ onButtonClick }) {

  const animation = { type: 'spring', duration: 3 };
const phone = window.innerWidth<= 768 ? true: false;
  const handleButtonClick = () => {
    onButtonClick();
  };

  return (
    <div className="hero" id="home">
      <div className="blur hero-blur"></div>
      <div className="left-h">
      <Header/>
        <div className="tagline">
          <span className="vip">Creating Beautiful Smiles Every Day</span>  {/* Update this content */}
          <motion.div initial={{ left: phone? '178px': '238px' }} whileInView={{ left: '8px' }} transition={{ ...animation, type: 'tween' }} className="taglineInner"></motion.div>
        </div>
        <div className='align'>
          <div className="hero-text">
            <span>Smile with Confidence: Expert Dental Care for a Brighter You!</span>
          </div>
          <div className="subtext-h">
            <span>Get personalized dental care for a healthy, bright smile. Trust our experts to keep your smile healthy and beautiful every day.</span>
          </div>
          <div className="figures">
            <div>
              <span> <NumberCounter end={5} start={0} delay='4' preFix='+' /> </span>
              <span>Years of Experience</span>
            </div>
            <div>
              <span><NumberCounter end={1500} start={0} delay='4' preFix='+' /></span>
              <span>Happy Patients</span>
            </div>
            <div>
              <span><NumberCounter end={150} start={0} delay='4' preFix='+' /></span>
              <span>Advanced Treatments</span>
            </div>
          </div>

          <div className="herobuttons">
            <button className="btn1" onClick={handleButtonClick}>CONTACT US</button>  {/* Update button text */}
            <button className="btn2" onClick={handleButtonClick}>BOOK APPOINTMENT</button>  {/* Update button text and add onClick event */}
          </div>
        </div>
      </div>
      <div className="right-h">
        <div className="buttona">
          <button className="btn1" onClick={handleButtonClick}> SCHEDULE CONSULTATION</button>
        </div>
        <motion.div initial={{ opacity: 0, x: -100 }} // Initial position off-screen to the left
        whileInView={{ opacity: 1, x: 0 }} transition={animation} className="anim1">
          <img src={anim1} alt="Animation 1" className='image1' />

        </motion.div>

        <motion.img initial={{ opacity: 0, x: 150 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ...animation, duration: 3, type: 'tween' }}
          animate={{ x: 0 }} // Adding animate property to override initial position
          style={{ marginLeft: "20px" }} src={heroimg} alt="" className='hero-image' />



        <motion.img initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ...animation, duration: 3, type: 'tween' }}
          animate={{ x: 0 }} // Adding animate property to override initial position
          style={{ marginLeft: "20px" }} src={back} alt="" className='back-image' />
        <div className="anim3">


        </div>
      </div>

    </div>
  );
}

export default Hero;
