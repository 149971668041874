
import img1 from "./../../src/assets/image1.jpg";
import img2 from "./../../src/assets/image2.jpeg";
import img3 from  "./../../src/assets/image3.jpeg";

export const TestData = [
    {
        image: img1,
        review: '"Dr.Maham Bilal and their team are fantastic! They always make me feel comfortable and explain everything clearly. My smile has never looked better. Highly recommend!"',
        name: 'Faseeh Ul Din',
        status: 'Computer Engineer',
    },
    {
        image: img2,
        review: '"Amazing experience with Dr.Maham Bilal! The office is modern, the staff is friendly, and the care is top-notch. I’m thrilled with my dental results. Best dentist ever!"',
        name: 'Arooba Akbar',
        status: 'Doctor',
    },
    {

        image: img3,
        review: "Outstanding service at Dr. Maham Bilal's office! They made me feel relaxed and cared for. My dental health has never been better and this good. I’m extremely satisfied!",
        name: 'Tabish Saleem',
        status: 'Doctor',
    }
];
